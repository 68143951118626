


















































import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FormCard from "@/components/FormCard.vue";
import Table from "@/components/Table.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import CompanyMixin from "@/mixins/CompanyMixin";
import { mixins } from "vue-class-component";

const ExamSlotsModule = namespace("exam-slots");
const LicenseClassModule = namespace("license-class");
const ExamDurationsModule = namespace("exam-durations");

@Component({
  components: { FscSimpleCard, Table, AbortButton, SaveButton, FormCard },
})
export default class LicenseSlotsConfiguration extends mixins(CompanyMixin) {
  public name = "LicenseSlotsConfiguration";

  @ExamSlotsModule.Action("findAll")
  public findExamLicenseClasses: any;

  @ExamSlotsModule.Getter("getDataList")
  public slotConditions: any;

  @ExamSlotsModule.Action("update")
  public updateExamSlots: any;

  @ExamSlotsModule.Getter("getIsLoading")
  public examSlotsLoading: any;

  @LicenseClassModule.Getter("getIsLoading")
  public licenseLoading: any;

  public drivingSchoolSlotsConditions = [];

  public async mounted(): Promise<void> {
    await this.findExamLicenseClasses({
      resource: "exam-slots-config/current",
    });

    this.mapLicenseClass();
  }

  public mapLicenseClass(): void {
    this.drivingSchoolSlotsConditions = this.slotConditions.map((slotCondition: any) => {
      return {
        id: slotCondition.id,
        slotConditionId: slotCondition?.slotConditionId,
        licenseClass: slotCondition?.licenseClass,
        condition: slotCondition?.condition,
        numberOfSlots: slotCondition?.numberOfSlots,
        examDurationMinutes: slotCondition?.examDurationMinutes,
      };
    });
  }

  public onAbort() {
    this.$router.go(0);
  }

  public onSubmit(): void {
    if (this.hasNotPermissionWrite) return;

    this.updateExamSlots({
      id: "current",
      data: this.drivingSchoolSlotsConditions,
      resource: "exam-slots-config",
      descriptionField: "licenseClass",
    });
  }
}
