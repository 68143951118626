















































































































































































import { Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { mixins } from "vue-class-component";
import ViewSwitchMixin from "@/mixins/ViewSwitchMixin";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import ArchiveModal from "@/components/Modal/ArchiveModal.vue";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import ModalMixin from "@/mixins/ModalMixin";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import CompanyMixin from "@/mixins/CompanyMixin";
import CreateButton from "@/components/Button/CreateButton.vue";
import ActionButton from "@/components/Button/ActionButton.vue";
import FscModal from "@/components/Modal/FscModal.vue";

const CheckListTemplateModule = namespace("checklist-template");

@Component({
  components: {
    ActionButton,
    CreateButton,
    FscSimpleCard,
    AbortButton,
    SaveButton,
    Validation,
    RemoveButton,
    ArchiveButton,
    EditButton,
    ArchiveModal,
    FscModal,
  },
})
export default class CheckListConfiguration extends mixins(ViewSwitchMixin, ModalMixin, CompanyMixin) {
  public name = "CheckListConfiguration";

  @CheckListTemplateModule.Action("findAll")
  public findAllCheckLists: any;

  @CheckListTemplateModule.Getter("getDataList")
  public allCheckLists: any;

  @CheckListTemplateModule.Action("findOne")
  public findSingleCheckList: any;

  @CheckListTemplateModule.Getter("getDataItem")
  public singleCheckListItems: any;

  @CheckListTemplateModule.Action("create")
  public createCheckList: any;

  @CheckListTemplateModule.Action("archive")
  public archiveCheckListTemplate: any;

  @CheckListTemplateModule.Getter("getArchiveSuccess")
  private archiveSuccess: any;

  @CheckListTemplateModule.Getter("getSuccess")
  public success: any;

  @CheckListTemplateModule.Action("update")
  public updateSingleCheckList: any;

  @CheckListTemplateModule.Getter("getIsLoading")
  public loadingCheckList: any;

  public checkListRowClicked: any | null = null;

  public checkListItems: any = [];

  public checkListFields = [
    { key: "name", label: "Checklists" },
    { key: "delete", label: "" },
    { key: "update", label: "" },
  ];

  public itemFields = [{ key: "name", label: "Items" }];

  public itemsTableVisible = false;

  public itemNameVisible = false;

  public checkListUpdateVisible = false;

  public checkList = {
    id: null,
    name: "",
    checklistItems: [
      {
        id: null,
        name: "",
      },
    ],
  };

  public checkItem = {
    id: null,
    name: "",
  };

  public selectedItems: any = [];

  public mounted(): void {
    this.fetchChecklistTemplates();
  }

  public onAbortCheckList(): void {
    this.$bvModal.hide("checkListPopover");
    this.$v.$reset();
  }

  public onAbortItemAdd(): void {
    this.$bvModal.hide("addItemPopover");
  }

  public onAbortUpdateCheckList(): void {
    this.selectedItems = [];
    this.checkListUpdateVisible = false;
  }

  public onAddItemClicked(): void {
    this.$bvModal.show("addItemPopover");
  }

  public onItemTableClose(): void {
    this.itemsTableVisible = false;
  }

  public onCreateCheckListClicked(): void {
    this.checkListUpdateVisible = false;
    this.fetchChecklistTemplates();
    this.checkList = {
      id: null,
      name: "",
      checklistItems: [],
    };
    this.$v.$reset();
    this.$bvModal.show("checkListPopover");
  }

  private fetchChecklistTemplates() {
    this.findAllCheckLists({
      resource: "checklist-templates",
    });
  }

  public async onCheckListRowClicked(checklist: any): Promise<void> {
    const id = checklist.id;
    await this.findSingleCheckList({
      id: id,
      resource: "checklist-templates",
    });
    if (!this.checkListUpdateVisible) {
      this.itemsTableVisible = true;
    }
    this.checkListRowClicked = checklist;
  }

  public onDeleteCheckListItem(): void {
    if (this.selectedItems === []) return;
    const newItems = this.checkList.checklistItems.filter((val: any) => !this.selectedItems.includes(val));
    this.checkList.checklistItems = newItems;
  }

  public onEditCheckList(): void {
    if (!this.checkListRowClicked) return;
    this.checkListUpdateVisible = true;
    this.itemsTableVisible = false;
  }

  public onSubmitAddItem(): void {
    const item = this.checkItem;
    if (item.name === "") {
      this.itemNameVisible = true;
      return;
    }
    const id = this.singleCheckListItems.id;
    this.checkList.checklistItems.push({ id: item.id, name: item.name });
    const checkList = {
      id: id,
      name: this.singleCheckListItems.name,
      checklistItems: this.checkList.checklistItems,
    };
    this.updateSingleCheckList({
      id,
      data: checkList,
      resource: "checklist-templates",
    });
    this.checkItem = { id: null, name: "" };
    this.$bvModal.hide("addItemPopover");
  }

  public onSubmitCheckList(): void {
    this.$v.$touch();

    if (this.$v.$invalid) return;

    if (this.typeCreate()) {
      const checkList = {
        name: this.checkList.name,
        checklistItems: this.checkList.checklistItems,
      };
      this.createCheckList({
        data: checkList,
        resource: "checklist-templates",
      });
      this.checkList = {
        id: null,
        name: "",
        checklistItems: [],
      };
      this.$bvModal.hide("checkListPopover");
      this.itemsTableVisible = false;
    }
  }

  public onUpdateSingleCheckList(): void {
    if (this.hasNotPermissionWrite) return;

    this.$v.$touch();

    if (this.$v.$invalid) return;

    const id = this.singleCheckListItems.id;
    const singleCheckList = {
      id: id,
      name: this.checkList.name,
      checklistItems: this.checkList.checklistItems,
    };
    this.updateSingleCheckList({
      id,
      data: singleCheckList,
      resource: "checklist-templates",
    });
  }

  private showArchiveModal(): void {
    if (!this.checkListRowClicked) {
      return;
    }
    this.showModal("check-list-archive-modal");
  }

  @Watch("archiveSuccess", { deep: true, immediate: true })
  private onArchiveSuccess(success: any): void {
    if (success) {
      this.checkListRowClicked = null;
      this.itemsTableVisible = false;
      this.checkListUpdateVisible = false;
      this.fetchChecklistTemplates();
    }
  }

  @Watch("singleCheckListItems")
  public onSingleCheckListChange(item: any): void {
    if (item) {
      this.checkList = item;
      this.checkListItems = [...item.checklistItems];
    }
  }

  @Watch("success")
  public onCreateCheckListSuccess(success: any): void {
    if (success) {
      this.findAllCheckLists({
        resource: "checklist-templates",
      });
      if (this.checkListRowClicked) {
        const id = this.checkListRowClicked.id;
        this.findSingleCheckList({
          id: id,
          resource: "checklist-templates",
        });
      }
    }
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      checkList: {
        name: { required },
      },
    };
  }
}
