










































import { Component, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Actions from "@/components/Actions.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import Table from "@/components/Table.vue";
import { namespace } from "vuex-class";
import TestingOrganizationCreate from "@/views/Configuration/TestingOrganization/TestingOrganizationCreate.vue";
import TestingOrganizationEdit from "@/views/Configuration/TestingOrganization/TestingOrganizationEdit.vue";
import { mixins } from "vue-class-component";
import CompanyMixin from "@/mixins/CompanyMixin";

const TestingOrganizationsModule = namespace("testing-organizations");

@Component({
  components: {
    FscSimpleCard,
    Actions,
    CreateButton,
    EditButton,
    Table,
    TestingOrganizationCreate,
    TestingOrganizationEdit,
  },
})
export default class TestingOrganization extends mixins(CompanyMixin) {
  public name = "TestingOrganization";

  @TestingOrganizationsModule.Action("create")
  public createTestingOrganization: any;

  @TestingOrganizationsModule.Getter("getId")
  public createdOrganizationId: any;

  @TestingOrganizationsModule.Action("update")
  public updateTestingOrganization: any;

  @TestingOrganizationsModule.Getter("getSuccess")
  public testingOrganizationSuccess: any;

  @TestingOrganizationsModule.Getter("getIsLoading")
  public testingOrganizationIsLoading: any;

  @TestingOrganizationsModule.Action("findAll")
  public findAllTestingOrganizations: any;

  @TestingOrganizationsModule.Getter("getDataList")
  public allTestingOrganizations: any;

  @TestingOrganizationsModule.Action("findOne")
  public findTestingOrganizationById: any;

  @TestingOrganizationsModule.Getter("getDataItem")
  public singleTestingOrganization: any;

  @TestingOrganizationsModule.Action("archiveTestOrganization")
  public archiveTestOrganizationById: any;

  protected fields = [
    {
      label: this.$t("general.test_organization"),
      key: "name",
    },
    {
      label: this.$tc("general.contact_person"),
      key: "contactPerson",
    },
    {
      label: this.$tc("general.archived", 1),
      key: "archived",
    },
  ];

  public visibleCreateForm = false;
  public visibleEditForm = false;
  public selectedOrganization: any = null;
  public sortedOrganizations: any = [];

  public async mounted(): Promise<void> {
    await this.findAllTestingOrganizations({
      resource: "testing-organizations/",
    });
    if (this.allTestingOrganizations) {
      this.sortedOrganizations = this.allTestingOrganizations.sort((a: any, b: any) => a.id - b.id);
    }
  }

  public onTestingOrganizationRowClicked(organization: any): void {
    this.selectedOrganization = organization;
  }

  public async testingOrganizationCreateSubmit(organization: any): Promise<void> {
    await this.createTestingOrganization({
      data: organization,
      resource: "testing-organizations",
    });
  }

  public async testingOrganizationUpdateSubmit(organization: any): Promise<void> {
    const id = this.selectedOrganization.id;
    organization.id = id;
    await this.updateTestingOrganization({
      id: id,
      data: organization,
      resource: "testing-organizations",
    });
  }

  public openCreateForm(): void {
    this.visibleCreateForm = true;
    this.visibleEditForm = false;
  }

  public closeCreateEditForm(): void {
    this.visibleCreateForm = false;
    this.visibleEditForm = false;
  }

  public openEditForm(): void {
    if (this.selectedOrganization) {
      this.visibleEditForm = true;
      this.visibleCreateForm = false;
    }
    return;
  }

  public async onCheckBoxChange(checkBox: any): Promise<void> {
    const options = {
      id: checkBox.id,
      archived: checkBox.archived,
    };
    await this.archiveTestOrganizationById(options);
  }

  @Watch("testingOrganizationSuccess")
  public async onOrganizationSuccess(success: any): Promise<void> {
    if (success) {
      await this.findAllTestingOrganizations({
        resource: "testing-organizations/",
      });
      if (this.allTestingOrganizations) {
        this.sortedOrganizations = this.allTestingOrganizations.sort((a: any, b: any) => a.id - b.id);
      }
      this.closeCreateEditForm();
    }
  }
}
