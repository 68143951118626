import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";

const URL = "student-notifications-config";

@Component
export default class WarningsRequestMixin extends Vue {
  public warningsList: any = [];
  public warningsLoading = false;
  public warningsSuccess = false;
  public warningsError: Record<any, any> | null = null;

  public async findAllWarnings(): Promise<void> {
    this.warningsLoading = true;
    this.warningsSuccess = false;
    this.warningsError = null;

    return await axios
      .get(`${URL}`)
      .then((response: AxiosResponse) => {
        this.warningsList = response.data;
        this.warningsSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.warningsError = error.response?.data;
      })
      .finally(() => {
        this.warningsLoading = false;
      });
  }

  public async updateWarnings(data: any): Promise<void> {
    this.warningsLoading = true;
    this.warningsSuccess = false;
    this.warningsError = null;

    return await axios
      .post(`${URL}`, data)
      .then((response: AxiosResponse) => {
        this.warningsSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.warningsError = error.response?.data;
      })
      .finally(() => {
        this.warningsLoading = false;
      });
  }
}
