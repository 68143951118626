









import { Component, Prop, Vue } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import TestingOrganizationForm from "@/views/Configuration/TestingOrganization/TestingOrganizationForm.vue";

@Component({
  components: {
    FscSimpleCard,
    TestingOrganizationForm,
  },
})
export default class TestingOrganizationCreate extends Vue {
  public name = "TestingOrganizationCreate";

  public organization = {
    name: "",
    contactPerson: "",
    email: "",
    postalCode: "",
    location: "",
    address: "",
    phone: "",
    info: "",
  };

  @Prop({ type: Boolean, required: false, default: () => false })
  public putLoading!: boolean;

  public onTestingOrganizationCreate(organization: any): void {
    this.$emit("organization-create", organization);
  }

  public onTestingOrganizationAbortCreate(): void {
    this.$emit("organization-abort");
  }
}
