









import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import TestingOrganizationForm from "@/views/Configuration/TestingOrganization/TestingOrganizationForm.vue";

@Component({
  components: {
    FscSimpleCard,
    TestingOrganizationForm,
  },
})
export default class TestingOrganizationEdit extends Vue {
  public name = "TestingOrganizationEdit";

  @Prop()
  selectedOrganization!: any;

  @Prop({ type: Boolean, required: false, default: () => false })
  public putLoading!: boolean;

  public organization: any = null;

  public onTestingOrganizationUpdate(organization: any): void {
    this.$emit("organization-update", organization);
  }

  public onTestingOrganizationAbortUpdate(): void {
    this.$emit("update-abort");
  }

  @Watch("selectedOrganization", { immediate: true, deep: true })
  public onSelectedOrganizationChange(org: any): void {
    if (org) {
      this.organization = org;
    }
  }
}
